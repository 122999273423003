import { useEffect } from "react";
import { ConnectedUser } from "../dto/connected-user.dto";
import Video from "./video"; // Import the Video component

interface ParticipantVideosProps {
    isInitializeComplete: boolean; // Boolean indicating if the initialization is complete
    participants: any[]; // Array of participants
    me: any; // Represents the current user
    users: ConnectedUser[];
}

const ParticipantVideos = (props: ParticipantVideosProps) => {
  if (!props.isInitializeComplete) <></>; // If initialization is not complete, return

  return <>{props.participants
    ?.map((participantAndStreamInfo, index) => {
      const { participant, streams } = participantAndStreamInfo; // Destructure the participant and streams
      const { username } = participant?.attributes; // Destructure the username from the participant attributes
      let streamsToDisplay = streams; // Initialize streamsToDisplay with the remote streams
      

      return (
        <div
          key={participant?.id}
          className={`participant-grid-container slot-${index + 1}`}
        >
          <Video
            users={props.users} // Pass the users
            me={props.me} // Pass the current user
            className={`remote-participant-video`} // CSS class for the remote participant video
            participant={participant} // Pass the participant information
            streamsToDisplay={streamsToDisplay} // Pass the streams to display
            
            username={username} // Pass the username
            participantSize={index + 1} // Pass the participant size
            key={participant?.id}
          />
        </div>
      );
    })}</>;
};

export default ParticipantVideos;
