import { FormGroup, Input } from "reactstrap";
import Close from "../../../theme/components/close";
import useModal from "../../../theme/hooks/use-modal";
import { ConnectedUser } from "../dto/connected-user.dto";
import { useContext, useEffect, useRef, useState } from "react";
import socketContext from "../context/socket-context";
import { set } from "lodash";
import { use } from "i18next";

interface VideoProps{
    className: string;
    participant: any;
    streamsToDisplay: any[];
    username: string;
    participantSize: number;
    me: any;
    users: ConnectedUser[];
}

const Video = (props: VideoProps) => {
    const modal = useModal();
    const socket = useContext(socketContext);
    const [visible, setVisible] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null); 
    const overlayContentInnerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [ aspectRatio, setAspectRatio ] = useState(16/9);    
    const [ videoSize, setVideoSize ] = useState({width: 0, height: 0});
    
    let updateVideoSize = (container : HTMLDivElement, video: HTMLVideoElement, overlayContentInnerRef: HTMLDivElement, width: number, height: number, ratio: number) => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;
            if (containerWidth / containerHeight > aspectRatio) {
                video.width = containerHeight * aspectRatio;
                video.height = containerHeight;
            } else {
                console.log("Container width else", containerWidth)
                video.width = containerWidth;
                video.height = containerWidth / aspectRatio;
            }
            overlayContentInnerRef.style.width = video.width + "px";
            overlayContentInnerRef.style.height = video.height + "px";
        }
    }

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.onloadedmetadata = () => {

                    if (videoRef.current) {
                        
                        const width = videoRef.current.videoWidth;
                        const height = videoRef.current.videoHeight;
                        setVideoSize({width: width, height: height});
                        setAspectRatio(width / height); 
                        
                        if (containerRef.current && overlayContentInnerRef.current) {
                            updateVideoSize(containerRef.current, videoRef.current, overlayContentInnerRef.current, width, height, aspectRatio);
                        }
                    }

                   
                }
            } 
        
        return () => {
            if (videoRef.current) {
                videoRef.current.onloadedmetadata = null;
            }
        }
    }, [videoRef.current])



    useEffect(() => {
      if (!containerRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
          if (containerRef.current && videoRef.current && overlayContentInnerRef.current) {
            console.log("Resizing video")
            updateVideoSize(containerRef.current, videoRef.current, overlayContentInnerRef.current, videoSize.width, videoSize.height, aspectRatio);
              
          }
      });
      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect(); // clean up 
    }, [containerRef]);
    

    useEffect(() => {
        if (videoRef.current) {
            const stream = props.streamsToDisplay.find((stream) => stream.mediaStreamTrack.kind === "video");
            if (stream) {
              videoRef.current.srcObject = new MediaStream();
              props.streamsToDisplay?.forEach((stream) =>
                (videoRef.current?.srcObject as any).addTrack(stream.mediaStreamTrack)
              );
            }

          }
    } , [videoRef, props.streamsToDisplay]);

    return (
      // Container for the video with relative positioning
        <div className="relative" ref={containerRef}>
          <div className="video-container">
          {/* Video element with key, muted attribute, and other necessary video p  roperties */}
          <video
            key={props.participant?.id} // Unique key for the video element
            muted={props.participant.attributes.username == props.me.userName} // Mute the video if it is local
            controls={false} // Disable video controls
            autoPlay // Enable autoplay for the video
            playsInline // Enable inline playback for the video
            className={props.className} // CSS class for the video element
            ref={videoRef}
            
          />
          </div>
                              {/* Overlay container for the username */}
            <div className="overlay-video-content" style={{position: "absolute", top:0, left: 0, bottom: 0, display:"flex" }}>
                    <div className="overlay-video-content-inner" ref={overlayContentInnerRef} style={{position: "relative"}}>
    
                            <div style={{position: "absolute", top: 10, left: 10, textAlign: "center", background: "rgba(19,16,21, 0.8)", fontSize: 12, borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10}}>
                                {props.username}  
                            </div>
                        { props.me.role == 2 && props.username != props.me.userName && <>
                                <div style={{position: "absolute", right: 50, top : 10, backgroundColor: "rgba(19,16,21, 0.8)", borderRadius: 20, paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginRight: 10, alignContent: "center"}}>
                                    <FormGroup switch style={{alignItems: "center", justifyItems: "center", justifyContent: "center", flexDirection: "row"}}>
                                        <label htmlFor="show-on-stream">Show on stream</label>
                                        <Input checked={visible} type="switch" id="show-on-stream" onChange={ (event) => {
                                            setVisible(visible);
                                            //socket.emit("user-visible", {socketID: socketUser?.socketID})
                                        }} />
                                    </FormGroup>
                                </div>
                                      
                        <div style={{position: "absolute", top:5, right:5}}>
                        <Close onClick={ () => {
                            const user = props.users.find( (user) => user.userName == props.username  )
                            modal.show("Étes-vous sur de vouloir retirer l'utilisateur du live ?", <>Étes-vous sur de vouloir retirer l'utilisateur du live ?</>, [{
                                    label: "Oui",
                                    onClick : () => {
                                        if (user){
                                            socket.emit("user-invite", {socketID: user.socketID});
                                        }
                                    }
                                },
                                {
                                    label: "Non",
                                    onClick : () => {

                                    }
                                }
                            ]);
                              
                        } }/>
                        </div>
                      </> }
                    </div>
                </div>
        </div>
    );
  };
  
  export default Video; // Export the Video component
  