import { Participant } from "amazon-ivs-web-broadcast/dist/src/stage/core-api/stage-connection";
import { getMediaForDevices, CAMERA, MIC, DeviceType } from "./mediaDevices";
import IVSBroadcastClient, { ConnectionState, LocalStageStream, Stage, StageEvents, StageStrategy, SubscribeType } from 'amazon-ivs-web-broadcast';
import { create } from "lodash";

// Function creates a local stage stream based on the specified device ID and type (CAMERA or MIC).
export const createLocalStageStream = async (deviceId: string, deviceType: DeviceType) => {

  // Warn and return if the device ID is null
  /*if (!deviceId) {
    console.warn("Attempted to set local media with a null device ID");
    return;
  }*/
  // Get media stream for the specified device
  const newDevice = await getMediaForDevices(deviceId, deviceType);
  
  // Create a LocalStageStream based on the device type
  const stageStream =
    deviceType === DeviceType.CAMERA
      ? new LocalStageStream(newDevice.getVideoTracks()[0])
      : new LocalStageStream(newDevice.getAudioTracks()[0]);

  return stageStream;
};

/**
 * Sets up the Strategy for 3 major actions a user performs: which streams to publish, should streams be published, subcribing to streams
 * @param {*} cameraStageStream The current user's camera MediaStream
 * @param {*} micStageStream The current user's microphone MediaStream
 * @returns strategy object
 */

const setupScreenStrategy = () => {

const screenStrategy = {
  audioTrack: undefined as LocalStageStream | undefined,
  videoTrack: undefined as LocalStageStream | undefined,
  

  updateTracks(newAudioTrack : any, newVideoTrack: any) {
    this.audioTrack = newAudioTrack;
    this.videoTrack = newVideoTrack;
    console.log("Updated Tracks: ", this.audioTrack, this.videoTrack);
  },



  // Method to define streams to publish
  stageStreamsToPublish() {
    console.log("Stage Streams to Publish: ", this.audioTrack, this.videoTrack);
      return [this.audioTrack, this.videoTrack];
  },

  // Method to determine participant publishing
  shouldPublishParticipant(participant: Participant) {
    return true;
  },

  // Method to determine type of subscription for participants
  shouldSubscribeToParticipant(participant: Participant) {
    return SubscribeType.NONE
  },

  close() {
    this.audioTrack?.mediaStreamTrack.stop();
    this.videoTrack?.mediaStreamTrack.stop();
    this.audioTrack = undefined;
    this.videoTrack = undefined;
  }
};

return screenStrategy; // Return the strategy object
};

const setupCameraStrategy = () => {

  const screenStrategy = {
    audioTrack: undefined as LocalStageStream | undefined,
    videoTrack: undefined as LocalStageStream | undefined,
    
  
    updateTracks(newAudioTrack : any, newVideoTrack: any) {
      this.audioTrack = newAudioTrack;
      this.videoTrack = newVideoTrack;
      console.log("Updated Tracks: ", this.audioTrack, this.videoTrack);
    },
  
  
  
    // Method to define streams to publish
    stageStreamsToPublish() {
      console.log("Stage Streams to Publish: ", this.audioTrack, this.videoTrack);
        return [this.audioTrack, this.videoTrack];
    },
  
    // Method to determine participant publishing
    shouldPublishParticipant(participant: Participant) {
      return true;
    },
  
    // Method to determine type of subscription for participants
    shouldSubscribeToParticipant(participant: Participant) {
      return SubscribeType.NONE
    },
  
    close() {
      this.audioTrack?.mediaStreamTrack.stop();
      this.videoTrack?.mediaStreamTrack.stop();
      this.audioTrack = undefined;
      this.videoTrack = undefined;
    }
  };
  
  return screenStrategy; // Return the strategy object
  };



export const joinCameraStage = async (token: string, stageRef : any, strategyRef: any) => {
  console.log("Joining Camera Stage Strategy");
  
  strategyRef.current = setupCameraStrategy();
  
  const cameraStageStream = await createLocalStageStream(
    "default",
    DeviceType.CAMERA
  );
  const micStageStream = await createLocalStageStream(
    "default",
    DeviceType.MIC
  );

  strategyRef.current.videoTrack = cameraStageStream;
  strategyRef.current.audioTrack = micStageStream;
  await strategyRef.current.updateTracks(micStageStream, cameraStageStream);

  let stage : Stage | null = new Stage(token, strategyRef.current);

  
  try {
    console.log("Joining Stage");

    await stage.join(); // Attempt to join the stage
  } catch (err) {
    console.error("Error joining stage: ", err);
    stage = null;
  }
  
  console.log("Stage joined ");
  stageRef.current = stage;
}


export const joinScreenStage = async (token: string, stageRef : any, strategyRef: any) => {
  console.log("Joining Screen Stage Strategy");
  
  strategyRef.current = setupScreenStrategy();
  
  const devices = await navigator.mediaDevices.getDisplayMedia({video: true, audio: true});
  const screenStageStream = new LocalStageStream(devices.getVideoTracks()[0]);
  const audioStageStream = new LocalStageStream(devices.getAudioTracks()[0]);

  strategyRef.current.videoTrack = screenStageStream;
  strategyRef.current.audioTrack = audioStageStream;
  await strategyRef.current.updateTracks(audioStageStream, screenStageStream);

  let stage : Stage | null = new Stage(token, strategyRef.current);

  
  try {
    console.log("Joining Stage");

    await stage.join(); // Attempt to join the stage
  } catch (err) {
    console.error("Error joining stage: ", err);
    stage = null;
  }
  
  console.log("Stage joined ");
  stageRef.current = stage;

}


// Function creates a strategy object for IVS stage, considering initialization status
export const setupStrategy = (
  isInitializeComplete : any// Parameter representing the initialization completion status
) => {
  // Check if the initialization is complete; if not, return nothing
  if (!isInitializeComplete) {
    return;
  }

  // More information can be found here: https://aws.github.io/amazon-ivs-web-broadcast/docs/v1.3.1/sdk-reference/enums/SubscribeType?_highlight=subscribetype

  const strategy = {
    isOwner: false,
    isInvited: false,

  


    // Method to define streams to publish
    stageStreamsToPublish() {
      return []
    },

    // Method to determine participant publishing
    shouldPublishParticipant(participant: Participant) {
      return false;
    },

    // Method to determine type of subscription for participants
    shouldSubscribeToParticipant(participant: Participant) {
      console.log("Should Subscribe to Participant: ", participant.userId);
      return SubscribeType.AUDIO_VIDEO;
    },
  };

  return strategy; // Return the strategy object
};

/**
 * Click handler for Join Stage Button
 */

export const joinStage = async (
  isInitializeComplete : any, // Indicates if the initialization is complete
  participantToken: string, // Token of the participant
  setIsConnected: Function, // Setter for the connection status
  setParticipants: Function, // Setter for the list of participants
  setScreenShareParticipant: Function, // Setter for the screen share participant
  stageRef: any, // Setter for the stage
  strategyRef: any
) => {


  if (!isInitializeComplete) return; // If the initialization is not complete, stop execution and return
  


  // Set up the strategy for the stage
  const strategy = setupStrategy(isInitializeComplete);


  strategyRef.current = strategy;

  // Create a new stage instance
  let stage : Stage | null = new Stage(participantToken, strategyRef.current);

  // Event listener for stage connection state changes
  stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state: any) => {
    // Update the connection status
    setIsConnected(state === ConnectionState.CONNECTED);

  });

  // Event listener for when participant streams are added
  stage.on(
    StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
    (participant : any, streams : any) => {
      console.log("Participant Media Added: ", participant, streams);

      if (participant.userId === "screen") {
        console.log("Setting Screen Share Participant: ", participant);
        setScreenShareParticipant({ participant, streams });
      }
      else {
        setParticipants((prevParticipants: any) => {
          const participantExists = prevParticipants.some(
          (participantObj: any) => participantObj.participant.id === participant.id);
          
  
          
          if (!participantExists) {
            return [...prevParticipants, { participant, streams }];
          } else {
            return prevParticipants;
          }
        });
      }

    }
  );

  // Event listener for when a participant leaves
  stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, (participant: any) => {
    console.log("Participant Left: ", participant);

    if (participant.userId === "screen") {
      setScreenShareParticipant(null);
    }else {
        // Update the list of participants by removing the participant who left

      setParticipants((prevParticipants: any[]) => {
        const filteredParticipants = prevParticipants.filter(
          ({ participant: currentParticipant }) => {
            return currentParticipant.id !== participant.id;
          }
        );
        return [...filteredParticipants];
      });
    }

 
  });

  try {
    await stage.join(); // Attempt to join the stage
  } catch (err) {
    stage = null;
  }

  stageRef.current = stage;
};

/**
 * Click handler for the Leave Stage button
 */
export const leaveStage = async (stage: any, setIsConnected: Function) => {
  await stage.leave();
  setIsConnected(false);
};
