import { Stage } from "amazon-ivs-web-broadcast";
import { Ref } from "react";
import { tr } from "yup-locales";

export const CAMERA = "camera";
export const MIC = "mic";

export enum DeviceType {
  CAMERA = "camera",
  MIC = "mic"
}

/**
 * Returns all devices available on the current device
 */
export const getDevices = async () => {
  // Prevents issues on Safari/FF so devices are not blank
  await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  const devices = await navigator.mediaDevices.enumerateDevices();

  // Get all video devices
  const videoDevices = devices.filter((d) => d.kind === "videoinput");
  if (!videoDevices.length) {
    console.error("No video devices found.");
  }

  // Get all audio devices
  const audioDevices = devices.filter((d) => d.kind === "audioinput");
  if (!audioDevices.length) {
    console.error("No audio devices found.");
  }

  return { videoDevices, audioDevices };
};

/**
 * Gets the media stream for the specified device ID and type.
 * @param {string} deviceId - The device ID.
 * @param {string} mediaType - The type of media ('video' or 'audio').
 * @returns {Promise<MediaStream>} - The resulting media stream.
 */
export const getMediaForDevices = async (deviceId: string , mediaType: string) => {
  const mediaConstraints = {
    video: {
      height: { ideal: 720, max: 1080 },
    },
    audio: true,
  };

  return navigator.mediaDevices.getUserMedia(mediaConstraints as any);
};

export const disableMedia = (stage: any, setCameraEnabled: Function, setMicEnabled: Function) => {
  
  const { videoStream } = stage.localParticipant;
  videoStream.setMuted(true);
  setCameraEnabled(false);

  const { audioStream } = stage.localParticipant;
  const isHidden = videoStream.isMuted;
  audioStream.setMuted(true);
  setMicEnabled(false);
}



// Function to handle toggling of media, takes in deviceType, stage, and setIsDeviceStopped as parameters
export const handleMediaToggle = (deviceType : DeviceType, stage: any, setDeviceIsEnabled: Function) => {
  // Check if the type is 'camera', if so, toggle the video stream
  if (deviceType === CAMERA) {
    // Access the video stream of the local participant from the stage
    const { videoStream } = stage.localParticipant;
    // Get the current status of the video stream
    const isHidden = videoStream.isMuted;
    // Toggle the mute status of the video stream
    videoStream.setMuted(!isHidden);
    // Update the state to reflect the change in visibility
    setDeviceIsEnabled(isHidden);
  }
  // If the type is 'mic', toggle the audio stream
  else if (deviceType === MIC) {
    // Access the audio stream of the local participant from the stage
    const { audioStream } = stage.localParticipant;
    // Get the current status of the audio stream
    const isMuted = audioStream.isMuted;
    // Toggle the mute status of the audio stream
    audioStream.setMuted(!isMuted);
    // Update the state to reflect the change in muting
    setDeviceIsEnabled(isMuted);
  }
}; 
