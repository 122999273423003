import { useContext, useEffect, useRef, useState } from "react";
import { EventDTO } from "../../event/dto/event.dto";
import socketContext from "../context/socket-context";
import { ChatData } from "../dto/chat-message.dto";
import { ConnectedUser } from "../dto/connected-user.dto";
import ActionBar from "./action-bar";
import { RightBar } from "./right-bar";

import ParticipantVideos from "./participant-video";
import { Participant } from "amazon-ivs-web-broadcast/dist/src/stage/core-api/stage-connection";
import { DeviceType, handleMediaToggle } from "../mediaDevices";
import { joinCameraStage, joinScreenStage } from "../stageUtils";



export interface VideoGalleryProps{
    me: ConnectedUser;
    users: ConnectedUser[];
    event: EventDTO;
    screenShareParticipant: Participant;
    participants: Participant[];
    setShowStartModal: Function;
    chatMessages: ChatData[]
}

export default function VideoGallery(props: VideoGalleryProps){
    const [cameraEnabled, setCameraEnabled] = useState(true);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [shareEnabled, setShareEnabled] = useState(false);
    const [communityEnabled, setCommunityEnabled] = useState(false);

    const [isMeSharing, setIsMeSharing] = useState<boolean | undefined>(undefined);
    const socket = useContext(socketContext);
    const screenShareStrategy = useRef<any>();
    const screenShareStage = useRef<any>();
    const cameraShareStage = useRef<any>();
    const cameraShareStrategy = useRef<any>();



    const toggleShare = () => {
        if (shareEnabled){
            screenShareStrategy.current.close()
            screenShareStage.current.leave()
            screenShareStage.current = undefined;
            screenShareStrategy.current = undefined;
            setIsMeSharing(undefined);
            setShareEnabled(false);
        } else {
            socket.emit("start-screen-share-request")
            setIsMeSharing(true);
            setShareEnabled(true);
        }
    }


    const toggleCamera = () => {
        handleMediaToggle(DeviceType.CAMERA, cameraShareStage.current, setCameraEnabled);
    }

    const toggleAudio = () => {
        handleMediaToggle(DeviceType.MIC, cameraShareStage.current, setAudioEnabled);
    }

    const updateVideos = () => {
        console.log("Updating videos")
    };



    const startCameraShare = async (token : string) => {
        setCameraEnabled(true);
        setAudioEnabled(true);
        await joinCameraStage(token, cameraShareStage, cameraShareStrategy);

    }
    
    const startScreenShare = async (token : string) => {    
        await joinScreenStage(token, screenShareStage, screenShareStrategy);
    }

    useEffect( () => {
        socket.on("start-screen-share-accepted", startScreenShare);
        socket.on("user-publish-token", startCameraShare);
        socket.emit("request-publish-token");

        return () => {
            socket.off("start-screen-share-accepted", startScreenShare);
            socket.off("user-publish-token", startCameraShare);
            if (cameraShareStage.current){

                cameraShareStrategy.current.close();
                cameraShareStage.current.leave();
                cameraShareStage.current = undefined;
                cameraShareStrategy.current = undefined;

            }

            if (screenShareStage.current){

                screenShareStrategy.current.close();
                screenShareStage.current.leave();
                screenShareStage.current = undefined; 
                screenShareStrategy.current = undefined;
            }
     
        
        }

    }, [])

    useEffect( () => {
        const updateMe = (user: ConnectedUser) => {
            
            if (user.invited == false && user.role != 2){
                if (cameraShareStage.current){

                    cameraShareStrategy.current.close();
                    cameraShareStage.current.leave();
                    cameraShareStage.current = undefined;
                    cameraShareStrategy.current = undefined;
                }

                if (screenShareStage.current){

                    screenShareStrategy.current.close();
                    screenShareStage.current.leave();
                    screenShareStage.current = undefined;
                    screenShareStrategy.current = undefined;
                }
         
            }
        }


        socket.on("user-updated",  updateVideos);
        socket.on("me", updateMe); 


        //updateVideos();

                    
        

        return () => {
            socket.off("user-updated",  updateVideos);
            socket.off("me", updateMe); 
        }
    }, [props.users])

    
    return <div className={`video-gallery ${props.screenShareParticipant != undefined && "screen-share-enabled"}`} style={{display:'flex', flex: 6, flexDirection: 'column', justifyContent: 'center'}}> 
          <div className="video-gallery-video-container" style={{flex: 1, display:"flex", maxHeight: "80%"}}> 
            { props.screenShareParticipant != undefined && <div className="screen-share-grid-container">
                <div className={`grid grid-1`}>
                    <ParticipantVideos
                        users={props.users}
                        me={props.me}
                        isInitializeComplete={true}
                        participants={props.screenShareParticipant ? [props.screenShareParticipant] : []}
                    /> 
                </div>
            </div> }
            <div className={`video-grid-container ${props.screenShareParticipant != undefined && "screen-share-enabled"}`}>
                <div className={`grid grid-${props.participants.length}`}>
                <ParticipantVideos
                    users={props.users}
                    me={props.me}
                    isInitializeComplete={true}
                    participants={props.participants}
                />
                </div>
            </div>
        </div>

        { <ActionBar className={"studio-desktop-action-bar"} showShare={isMeSharing == undefined || isMeSharing }  shareEnabled={shareEnabled} chatEnabled={props.event.chatEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare} users={props.users}/>}

        <div className="studio-mobile-bottom-bar">
            <div style={{flex: 5, display: "flex"}}>
                <RightBar className="studio-mobile-right-bar" forceShowCommunityWidget={communityEnabled} users={props.users} me={props.me!} chatMessages={props.chatMessages} chatEnabled={props.event ? props.event.chatEnabled : false} socket={socket} />
            </div>
            <div>
                { <ActionBar direction="column" showShare={isMeSharing || shareEnabled } toggleCommunity = { () => { setCommunityEnabled(!communityEnabled) }}className={"studio-mobile-action-bar"} chatEnabled={props.event.chatEnabled} shareEnabled={shareEnabled} audioEnabled={audioEnabled} cameraEnabled={cameraEnabled} event={props.event} me={props.me} setShowStartModal={props.setShowStartModal} socket={socket} toggleAudio={toggleAudio} toggleCamera={toggleCamera} toggleShare={toggleShare} users={props.users}/>}
            </div>
        </div>

    </div>  
}