import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import authService from "../../login/services/auth-service";
import { useNavigate } from "react-router-dom";
import CreateForm, { CreateProfil } from "../../viewer/components/create-form";
import CompleteProfilForm, { CompleteProfilSchema } from "../components/complete-profil-form";
import CertifyProfilForm, { CertifyProfile } from "../components/certify-profil-form";
import { CertifyProfilSchema } from "../components/validate-profil-form";
import { setUser, setUserData } from "../../login/login-slice";
import { t } from "i18next";


export default function SwitchCreatorProfil() {

  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigation = useNavigate();
  const [profile, setProfile] = useState<CompleteProfilSchema>();
  const user = useAppSelector((state) => state.login.user);
  const onCompleteProfilSubmit: SubmitHandler<CompleteProfilSchema> = (data) => {

    setProfile(data);
  }

  const onCertifyProfilSubmit: SubmitHandler<CertifyProfile> = (data) => {
    let partner = localStorage.getItem("partner");
    const partnerId = partner ? partner : undefined;

    authService.switchCreator(profile!.firstName, profile!.lastName, profile!.category, profile!.photo, partnerId, data.tiktok, data.instagram,  data.youtube, data.twitter, data.linkedin, profile?.channelName, profile?.phone).then( (response) => {
      if (response.status === 200 || response.status === 201) {
        setError(undefined);
        console.log(response.data)
        dispatch(setUserData(response.data))
        localStorage.removeItem("partner");

        navigation('/')
      }else {
        setError(response.data.message);
      }
    }).catch( (error) => {
      setError(error.message);
    });
  };

  return <>
      { !profile && <CompleteProfilForm onSubmit={onCompleteProfilSubmit} firstName={user?.firstName} lastName={user?.lastName} error={error} submitLabel={t("form.next")} />}
      {  profile && <CertifyProfilForm onSubmit={onCertifyProfilSubmit} error={error} submitLabel={t("form.validae_creator_account")} />}

  </>;
}