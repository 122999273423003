import { ChatData, ChatMessage } from "../dto/chat-message.dto";
import CreatorImage from "../../creator/components/creator-image";
import { ConnectedUser } from "../dto/connected-user.dto";
import { UserActionDropdown } from "./user-action-dropdown";

interface ChatMessageProps{
    me: ConnectedUser
    message : ChatData
    onClickAlert: Function
    onClickBan: Function
    onClickInvite: Function
    onClickPromote: Function
    user: ConnectedUser
}

export default function ChatMessageWidget(props: ChatMessageProps){

    return <><div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flex: 1}}>
        <div style={{width: 30, height: 30, borderRadius: 25, display: "flex", alignItems: "center", justifyContent: "center"}}>
            { "userName" in props.message && <CreatorImage style={{height: 30, maxWidth: "none"}} userName={(props.message as ChatMessage).userName} image={(props.message as ChatMessage).picture}/> }
        </div>
        { "userName" in props.message ? <>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 10}}>
            <h2 style={{color: "#bfbfbf", fontSize: 15, margin: 0, textShadow: "1px 1px 2px #363636"}}>{(props.message as ChatMessage).userName}</h2>
            <div style={{fontSize: 15, margin: 0, textShadow: "1px 1px 2px #363636" }}>{props.message.message}</div>
        </div>
        <UserActionDropdown me={props.me} user={props.user} onClickAlert={props.onClickAlert} onClickBan={props.onClickBan} onClickInvite={props.onClickInvite} onClickPromote={props.onClickPromote} /> </>
        :  <div style={{color: "yellow", marginLeft: 10}}>{ props.message.message }</div>}
    </div></>

}